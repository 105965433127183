<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            label="목표년도"
            name="year"
            type="year"
            default="today"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="SAFETY_ACTION_TARGET_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            label="분야"
            name="safetyActionTargetTypeCd"
            v-model="searchParam.safetyActionTargetTypeCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="안전활동 목표 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          rowKey="policyId"
          :columnSetting="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="bookPopup" />
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'safety-action-target',
  data() {
    return {
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'year',
            field: 'year',
            label: '목표년도',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'safetyActionTargetTypeName',
            field: 'safetyActionTargetTypeName',
            label: '분야',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'targetTitle',
            field: 'targetTitle',
            label: '사업장 목표',
            type: "link",
            align: 'left',
            sortable: true,
          },
          {
            name: 'state',
            field: 'state',
            label: '상태',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        year: '',
        safetyActionTargetTypeCd: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        isFull: true,
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          safetyActionTargetId: 'SATI000001',
          plantName: '1사업장',
          year: '2022',
          safetyActionTargetTypeName: '안전보건',
          targetTitle: '2022년도 안전활동 목표',
          state: '계획 완료',
          useFlag: 'Y',
          stepCd: '002',
        },
        {
          safetyActionTargetId: 'SATI000002',
          plantName: '1사업장',
          year: '2022',
          safetyActionTargetTypeName: '환경',
          targetTitle: '2022년도 환경활동 목표',
          state: '계획 작성중',
          useFlag: 'Y',
          stepCd: '001',
        },
        {
          safetyActionTargetId: 'SATI000003',
          plantName: '1사업장',
          year: '2021',
          safetyActionTargetTypeName: '환경',
          targetTitle: '2021년도 환경활동 목표',
          state: '결과 완료',
          useFlag: 'Y',
          stepCd: '003',
        },
      ];
    },
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./safetyActionTargetDetail.vue"}`);
      this.popupOptions.title = '안전보건 경영방침 상세';
      this.popupOptions.param = {
        safetyActionTargetId: result ? result.safetyActionTargetId : '',
        stepCd:  result ? result.stepCd : '',
      };
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
